import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      points: 0,
      name: ''
    }
  },
  getters: {
    GET_USER_POINTS: state => {
      return state.user.points
    },
    GET_USER_NAME: state => {
      return state.user.name
    }
  },
  mutations: {
    SET_USER_POINTS: (state, payload) => {
      state.user.points = payload
    },
    SET_USER_NAME: (state, payload) => {
      state.user.name = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
