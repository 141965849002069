<template>
    <div class="header">
        <nav>
            <router-link class="menu-item" to="/">Home</router-link>
            <router-link class="menu-item" to="/ranking">Ranking</router-link>
            <router-link class="menu-item" to="/online-players">Online players</router-link>
            <router-link class="menu-item" to="/donate">💎Donate</router-link>
            <a class="menu-item" @click="logout()">Logout</a>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'HeaderComponent',
    methods: {
        logout() {
            this.$router.push('/login')
            localStorage.removeItem('token')
            localStorage.removeItem('username')
            localStorage.removeItem('points')
            this.$store.commit('SET_USER_POINTS', 0)
            this.$store.commit('SET_USER_NAME', '')
        }
    }
}
</script>

<style scoped>

    .header {
        position: relative;
        background-color: rgba(0,0,0, .9);
        width: 100%;
        padding: 90px 30px;

    }
    .menu-item {
        background: blueviolet;
        color: aliceblue;
        text-decoration: none;
        padding: 7px 30px;
        cursor: pointer;
        transition: .3s;
    }
    .menu-item:first-of-type {
        border-radius: 10px 0  0 10px;
    }
    .menu-item:last-of-type {
        border-radius: 0 10px 10px 0;
        background-color: black;
    }
    .menu-item:hover {
        background: tomato;
        color: aliceblue;
        text-decoration: none;
        padding: 7px 30px;
    }
</style>