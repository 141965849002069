<template>
    <div class="main-container">
        <div class="sidebar">
            <ProfileCard></ProfileCard>
            <slot name="sidebar"></slot>
        </div>
        <div class="main-content">
            <slot name="main"></slot>
        </div>
    </div>
</template>

<script>
import ProfileCard from './ProfileCard.vue';

    export default {
    name: 'Content',
    components: { ProfileCard }
}
</script>

<style scoped>

    .main-container {
        display: flex;
        justify-content: space-between;
    }

    .sidebar {
        background-color: #111;
        flex-basis: 25%;
    }

    .main-content {
        background-color: black;
        flex-basis: 75%;
        display: flex;
        flex-direction: column;
        padding: 25px;
        min-height: calc(100vh - 250px);
    }

</style>