<template>
  <div>
    <div class="container">
      <div class="content">
        <HeaderComponent></HeaderComponent>
      </div>
    </div>
    <div class="container">
      <div class="content">
        <Content>
          <template slot="sidebar">
          </template>
          <template slot="main">
            <div class="loader">
              <Loader :hidden="false"></Loader>
            </div>
            <div class="content">
              <h2>Welcome to RS Community</h2>
              <br>
              <p>
                <b>Server Info</b><br>
                - Experience rate: 4x <br>
                - Magic item drop rate: 3x <br>
                - Level limit: 1900 <br>
                - Current max map level: 1035 <br>
              </p><br>
              
              <p>
                <b>Common events</b><br>
                - We almost always have gold time on the weekends<br>
                - GM Bell (3hours duration bell or 1h 3x experience bell)<br>
                - Dropping coins to buy special NPC items<br>
                - We accept new ideas<br>
              </p><br>

              <p>
                <b>Download instructions</b><br>
                - You need to install the client <b>as administrator</b><br>
                - Please don't change default installation folder<br>
              </p><br>
            </div>            

          </template>
        </Content>
      </div>
    </div>
  </div>
</template>

<script>

import Content from '@/components/Content.vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import Loader from '@/components/Loader.vue';
export default {
  name: 'HomeView',
  components: {
    HeaderComponent,
    Content,
    Loader
}
}
</script>
