<template>
    <div class="loading spin" v-show="hidden">
        <img src="../assets/load.svg">
    </div>
  </template>
  
  <script>
  export default {
    name: 'Loader',
    props: {
      hidden: Boolean
    }
  }
  </script>
  