<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  require('@/assets/css/boot.css')

  export default {
    name: 'App',
    mounted(){
      this.loadSavedData()
    },
    methods: {
      loadSavedData() {
        this.$store.commit('SET_USER_POINTS', parseInt(localStorage.getItem('points')) || 0)
        this.$store.commit('SET_USER_NAME', localStorage.getItem('username') || '')
      }
    }
  }
</script>
