<template>
    <div class="profile-card-container">
        <div class="profile-card">
            <span class="name"><b>🚀 Username:</b> {{ username }}</span><br>
            <span class="points"><b>💎 GEMS:</b> {{ points }}</span>
        </div>
        <div class="change_password_btn">
            <button @click="callChangePasswordView()">🔑 Change Password</button>
        </div>
        <button @click="downloadClient()">🎮 Download Game</button>
        <button @click="joinDiscord()"> 🗯️ Discord Channel</button>
        <button @click="donate()"> 🎁 Donate</button>
    </div>
</template>

<script>
import { CLIENT_DOWNLOAD_URL, DISCORD_CHANNEL } from '@/common/defines'

export default {
    name: 'ProfileCard',
    computed: {
        username() {
            return this.$store.getters.GET_USER_NAME
        },
        points() {
            return this.$store.getters.GET_USER_POINTS
        }
    },
    methods: {
        callChangePasswordView() {
            this.$router.push('change-password')
        },
        downloadClient() {
            const url = CLIENT_DOWNLOAD_URL
            window.open(url)
        },
        joinDiscord() {
            const url = DISCORD_CHANNEL
            window.open(url)
        },
        donate() {
            this.$router.push('/donate')
        }
    }
}
</script>

<style scoped>
.profile-card-container {
    padding: 20px;
}

.profile-card {
    width: 100%;
    margin-bottom: 15px;
    padding: 20px 15px;
    border-radius: 7px;
    background-color: black;

}
</style>