import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import axios from 'axios'
import { DEFAULT_ENDPOINT } from '@/common/defines'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/online-players',
    name: 'online_players',
    component: () => import('../views/OnlinePlayersView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/ranking',
    name: 'ranking',
    component: () => import('../views/RankingView.vue')
  },
  {
    path: '/change-password',
    name: 'change_password',
    component: () => import('../views/ChangePasswordView.vue')
  },
  {
    path: '/donate',
    name: 'donate',
    component: () => import('../views/DonateView.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const FREE_ROUTES = ['login', 'register']

  if ( !FREE_ROUTES.includes(to.name) ) {
    const token = localStorage.getItem('token') || ''
    axios.get(`${DEFAULT_ENDPOINT}/check-token`, {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
      .then(_ => {
        next()
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem('token')
          router.push('/login')
        }
      })
  } else {
    next()
  }

})

export default router
